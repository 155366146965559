import 'lazysizes';
var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
$().ready(function(){    
    setupSlideMenu();
    floatLabel();
    sponsorScroll($(".banners-logos_upper"), "right");
    sponsorScroll($(".banners-logos_lower"), "left");
    $('#map-click').click(function(){
        // shield the map on mobile for easy scrolling
        $(this).hide();
    });
    $(".banner-rotate").each(function(index,el){
        rotateBanner($(el));
    });
    $(".banner-slider").each(function(index,el){
        slideBanner($(el));
    });
});

function setupSlideMenu(){
/* main menu */
    $('#mobile-menu, .slide-menu h3').click(function(){
        $("body").toggleClass('slide-menu-active');
    });
    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });
    $(".slide-menu li").each(function(index, element) {
        var $el = $(element);
        if ($el.find('ul').length > 0) {
            var expander = $('<span class="expand"><i class="nav-arrow"></i></span>');
            $el.append(expander);
            expander.click(function(e) {
                e.stopPropagation();
                $el.toggleClass('expand-active');
            });
            
        }
    });
/* product menu */
    $('#product-menu').click(function(){
        $("body").toggleClass("product-menu-active");
    });
}



//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener('lazybeforeunveil', function(e){
    var container = e.target;
    var bgUrl = container.dataset.bgUrl;
    if (bgUrl) {
        container.style.backgroundImage = "url('" + bgUrl + "')";
        delete container.dataset.bgUrl;
    }
});

function floatLabel(){
    var formFields = $('.form-field');
  
    formFields.each(function() {
        var field = $(this);
        var input = field.find('input');
        
        if (input.length==0){
            input = field.find('textarea');
        }

        var label = field.find('label');
        
        function checkInput() {
            var valueLength = input.val().length;
            
            if (valueLength > 0 ) {
            label.addClass('freeze');
            } else {
                label.removeClass('freeze');
            }
        }

        input.change(function() {
            checkInput();
        });

        // Submit on enter
        input.keyup(function(event) {
            if (event.which === 13){
                event.preventDefault();
                $(this.form).submit();
            }
        });
    });
}


function sponsorScroll(container, direction){
    var banner = container.find(".banner");
    container.css("overflow", "hidden");
    if (banner.length > 0 ){

       var stage = container.find(".banner-stage");
       if (container[0].scrollWidth > container[0].clientWidth) {
           
            if(direction == 'right'){
                container.scrollLeft(stage.find(".banner").first().outerWidth(true));
            }

           startScroll();
           container.hover(function(){container.stop();}, startScroll);
       }
       function startScroll(){
            stage.append(stage.find(".banner"));
            stage.css("padding-right","50vw");
            var bwidth = stage.find(".banner").first().outerWidth(true); //+margin
            
          
            if(direction == 'left'){
                var timing = (bwidth - container.scrollLeft()) * 8;
                container.animate({scrollLeft: bwidth}, timing, "linear", reset);
            }
            else{
                var timing = (bwidth * 8) - ((bwidth - container.scrollLeft()) * 8);
                container.animate({scrollLeft: 0}, timing, "linear", reset);
            }
       }
       function reset(){
            if(direction == 'left'){
                container.scrollLeft(0);
                stage.children().first().appendTo(stage);
            }
            else{
                container.scrollLeft(stage.find(".banner").first().outerWidth(true));
                stage.children().last().prependTo(stage);
            }
           startScroll();
       }
    } else {
       container.parent("div").hide();
    };
}

function rotateBanner($banner){

    var $images = $banner.find('.banner-image');
  
    function activateNav(image){
        $images.removeClass('active');
        image.addClass('active');
    }
    function stepCarousel() {
        var $next = $images.filter('.active').next();
        if (!$next.length) {
            $next = $images.first();
        }
        activateNav($next);
    }

    function startCarousel(){
        setInterval(stepCarousel, 7000);
    }
 
    startCarousel();
}
function slideBanner($banner){
    var $navs = $banner.find('.banner-nav-item');
    var $images = $banner.find('.banner-image');
    if ($navs.length > 1 ){
        $banner.find('.banner-navigation').show();
    }
    $.each($navs, function( index, value ) {
        $(value).click(function() {
            $('.banner-nav-item').removeClass('active');
            $(this).addClass('active');
            $images.removeClass('active');
            $($images[index]).addClass('active');
        });
    });
}